export default function Support() {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div>
        Về chúng tôi: AZinsu là một ứng dụng di động mang đến cho người dùng
        trải nghiệm .
      </div>
      <div
        style={{
          paddingLeft: 10,
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <li>Phiên Bản Hiện Tại: 2.0.1</li>
        <li>Ngày Phát Hành Đầu Tiên: 01/01/2023</li>
        <li>Ngôn Ngữ:</li>
      </div>
      <div
        style={{
          paddingLeft: 40,
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          listStyleType: "circle",
        }}
      >
        <li>Ứng dụng: Tiếng Việt.</li>
        <li>Hỗ trợ: Tiếng Anh.</li>
      </div>
      <div>Chính Sách Bảo Mật</div>
      <div
        style={{
          paddingLeft: 10,
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <li>
          Chúng tôi đặt quan trọng lớn vào việc bảo vệ quyền riêng tư của người
          dùng. Để biết thêm chi tiết về cách chúng tôi thu thập, sử dụng và bảo
          vệ thông tin cá nhân, vui lòng đọc Chính Sách Bảo Mật của chúng tôi
          tại đây.
        </li>
      </div>
      <div>Giấy Phép và Điều Khoản Sử Dụng</div>
      <div
        style={{
          paddingLeft: 10,
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <li>
          {" "}
          Trước khi sử dụng ứng dụng của chúng tôi, vui lòng đọc và hiểu rõ Giấy
          phép và Điều Khoản Sử Dụng. Bạn có thể xem chi tiết tại
          www.example.com/....
        </li>
      </div>
      <div>
        Hỗ trợ: Nếu bạn gặp vấn đề hoặc có câu hỏi liên quan đến ứng dụng của
        chúng tôi, vui lòng liên hệ với đội ngũ hỗ trợ của chúng tôi. Chúng tôi
        sẽ cố gắng giải quyết mọi vấn đề của bạn càng sớm càng tốt.
      </div>
      <div
        style={{
          paddingLeft: 10,
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <li> Email Hỗ Trợ: support@example.com</li>
        <li> Hỗ trợ tại app: Link to screen Support.</li>{" "}
        <li> Số điện thoại hỗ trợ khách hàng : 222222</li>
      </div>
    </div>
  );
}
