export default function Faq() {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <li style={{ fontWeight: "bold" }}>Làm cách nào để đổi mật khẩu?</li>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          paddingLeft: "10px",
        }}
      >
        <div>- Trong giao diện chính, chọn setting.</div>
        <div> - Trong setting chọn đổi nhập khẩu.</div>
        <div>- Nhập mật khẩu hiện tại.</div>
        <div> - Nhập và xác nhận mật khẩu mới</div>
      </div>
      <li style={{ fontWeight: "bold" }}>
        Tại sao không thể thực hiện chức năng yêu cầu bồi thường?
      </li>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          paddingLeft: "10px",
        }}
      >
        {" "}
        <div>- Thông tin của bạn nhập sai.</div>
        <div>- Lỗi hệ thống</div>
      </div>

      <li style={{ fontWeight: "bold" }}>
        Làm cách nào để tạo yêu cầu bồi thường?
      </li>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          paddingLeft: "10px",
        }}
      >
        - Để tạo yêu cầu bồi thường bạn phải có hợp đồng bảo hiểm và các tài
        liệu, thông tin về bệnh và cơ sở khám chữa bệnh.
      </div>
      <div style={{display:'flex',flexDirection:'column' , gap:'5px'}}>
        <div style={{ fontWeight: "bold" }}>Liên hệ hỗ trợ:</div> Nếu bạn có bất
        kỳ vấn đề hoặc thắc mắc nào không được giải đáp trong Trung tâm Trợ
        giúp, vui lòng liên hệ với chúng tôi qua các kênh hỗ trợ sau:{" "}
        <div style={{ display: "flex" }}>
          <div>Email:</div>{" "}
          <div style={{ textDecoration: "underline", paddingLeft: 10 }}>
            support@azinsu.com
          </div>{" "}
        </div>
        <div style={{ display: "flex" }}>
          <div>Điện thoại:</div>{" "}
          <div style={{ textDecoration: "underline", paddingLeft: 10 }}>
            +84 123 456 789
          </div>{" "}
        </div>
        <div style={{ display: "flex" }}>
          <div> Trang web:</div>{" "}
          <div style={{ textDecoration: "underline", paddingLeft: 10 }}>
            www.azinsu.com/support{" "}
          </div>{" "}
        </div>
        <div ><div style={{ fontWeight: "bold", width:'100%' }}>Gửi phản hồi:</div> Chúng tôi rất
        đánh giá mọi ý kiến phản hồi từ người dùng. Nếu bạn có góp ý hoặc đề
        xuất nào để cải thiện ứng dụng AZinsu, vui lòng gửi phản hồi của bạn qua
        ứng dụng hoặc các kênh liên lạc khác.</div>
      </div>
    </div>
  );
}
