export default function Policy() {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div style={{ fontWeight: "bold" }}>Phạm vi và Mục đích:</div>
      <div>
        Điều khoản và Chính sách này áp dụng cho việc sử dụng ứng dụng AZinsu,
        một sản phẩm của tập đoàn FPT, để gửi yêu cầu bảo hiểm. Bằng cách sử
        dụng ứng dụng này, người dùng đồng ý tuân thủ các điều khoản và điều
        kiện sau đây.
      </div>
      <div style={{ fontWeight: "bold" }}>Tuổi Tác:</div>
      <div>
        Bạn phải đảm bảo rằng bạn đủ tuổi để sử dụng ứng dụng và bạn có thể chịu
        trách nhiệm về mọi hoạt động của bạn trong khi sử dụng ứng dụng
      </div>
      <div style={{ fontWeight: "bold" }}>Điều Khoản Thanh Toán:</div>
      <div>
        Các dịch vụ có thể yêu cầu thanh toán và bạn đồng ý trả tiền cho mọi
        dịch vụ mà bạn chọn sử dụng. Thông tin thanh toán của bạn sẽ được xử lý
        một cách an toàn và bảo mật.
      </div>
      <div style={{ fontWeight: "bold" }}>Điều khoản sử dụng:</div>
      <div>
        Người dùng phải cung cấp thông tin chính xác và hoàn chỉnh khi gửi yêu
        cầu bảo hiểm qua ứng dụng AZinsu. Người dùng không được sử dụng ứng dụng
        AZinsu cho các mục đích pháp luật hoặc hoạt động vi phạm quy định. Người
        dùng chịu trách nhiệm bảo mật thông tin cá nhân và tài khoản của mình và
        không chia sẻ thông tin này với bất kỳ bên thứ ba nào.
      </div>
      <div style={{ fontWeight: "bold" }}>Bảo vệ thông tin cá nhân:</div>
      <div>
        Chúng tôi cam kết bảo vệ thông tin cá nhân của người dùng và tuân thủ
        các quy định về bảo mật dữ liệu. Chúng tôi sẽ không tiết lộ thông tin cá
        nhân của người dùng cho bất kỳ bên thứ ba nào ngoại trừ những trường hợp
        được quy định bởi luật pháp.{" "}
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}> Quyền lợi của người dùng:</p> Người
        dùng có quyền truy cập, chỉnh sửa hoặc xóa thông tin cá nhân của mình từ
        ứng dụng AZinsu. Họ cũng có quyền yêu cầu xem xét hoặc rút lại sự cho
        phép về việc sử dụng thông tin cá nhân.
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}> Nội Dung Người Dùng: </p> Khi sử dụng
        ứng dụng, bạn không được gửi hoặc chia sẻ nội dung bất hợp pháp, đe dọa,
        phỉ báng, gây hấn, hay vi phạm bất kỳ quy định pháp luật nào. Chúng tôi
        có quyền loại bỏ bất kỳ nội dung nào mà chúng tôi cho là không tuân thủ.
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}> Chấm dứt hợp đồng: </p> Chúng tôi có
        quyền chấm dứt hợp đồng với bất kỳ người dùng nào vi phạm điều khoản và
        điều kiện trong Chính sách này mà không cần báo trước.
      </div>
      <div>
      <p style={{ fontWeight: "bold" }}> Thay đổi và Cập nhật: </p>Chúng tôi có thể cập nhật điều khoản và chính sách
        này từ thời gian này sang thời gian khác để phản ánh các thay đổi trong
        luật pháp hoặc thực tiễn kinh doanh của chúng tôi. Người dùng sẽ được
        thông báo rõ ràng về bất kỳ thay đổi nào qua ứng dụng AZinsu. Vui lòng
        đọc kỹ và hiểu rõ các điều khoản và điều kiện trước khi sử dụng ứng dụng
        AZinsu. Nếu bạn có bất kỳ câu hỏi hoặc ý kiến, vui lòng liên hệ với
        chúng tôi qua các kênh hỗ trợ được cung cấp trong ứng dụng.
      </div>
    </div>
  );
}
