import "./App.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import Privay from "./privacy";
import Support from "./support";
import Policy from "./policy";
import HelpCenter from "./helpCenter";
import Faq from "./faq";

function App() {
  return (
    <div className="App" style={{ display: "flex", justifyContent: "center" }}>
      <div
        className="col-3"
        style={{
          color: "#1964af",
          fontWeight: "bold",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #b5b5b5",
            textAlign: "left",
            paddingBottom: "10px",
            fontSize: 16,
            paddingTop:21,
            paddingLeft:10
          }}
        >
          Điều kiện sử dụng
        </div>
      </div>
      <div className="col-9">
        <Accordion activeIndex={0}>
          <AccordionTab header="Privacy">
            <Privay />
          </AccordionTab>
          <AccordionTab header="Support">
            <Support />
          </AccordionTab>
          <AccordionTab header="Terms and policy">
            <Policy />
          </AccordionTab>
          <AccordionTab header="Help center">
            <HelpCenter />
          </AccordionTab>
          <AccordionTab header="Faq">
            <Faq />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}

export default App;
