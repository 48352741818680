export default function Privay() {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div>
        Chúng tôi tại AZinsu, một phần của tập đoàn FPT, cam kết bảo vệ quyền
        riêng tư và bảo mật thông tin cá nhân của người dùng của chúng tôi.
        Chính sách này mô tả cách chúng tôi thu thập, sử dụng và bảo vệ thông
        tin cá nhân của bạn khi bạn sử dụng ứng dụng AZinsu. Vui lòng đọc kỹ
        chính sách này để hiểu rõ cách chúng tôi sử dụng thông tin cá nhân của
        bạn
      </div>
      <div style={{ fontWeight: "bold" }}>Thông Tin Thu Thập và Sử Dụng</div>
      <div>
        Khi bạn sử dụng ứng dụng AZinsu, chúng tôi có thể thu thập một số thông
        tin cá nhân từ bạn, bao gồm:
      </div>
      <div>
        Thông tin cá nhân bạn cung cấp khi đăng ký tài khoản hoặc thêm hợp đồng
        bảo hiểm, bao gồm tên, địa chỉ email và số điện thoại.
      </div>
      <div>
        Thông tin về hợp đồng bảo hiểm bạn thêm vào ứng dụng, bao gồm chi tiết
        về các chính sách và yêu cầu bồi thường.
      </div>
      <div>
        Thông tin giao dịch và thanh toán, nếu bạn sử dụng các dịch vụ trả phí
        trong ứng dụng.
      </div>
      <div>
        Chúng tôi sử dụng thông tin cá nhân của bạn để cung cấp dịch vụ cho bạn,
        bao gồm xác nhận và xử lý các yêu cầu bồi thường bảo hiểm, cung cấp
        thông tin hợp đồng và thanh toán. Chúng tôi cũng có thể sử dụng thông
        tin này để liên hệ với bạn liên quan đến dịch vụ hoặc cập nhật về sản
        phẩm mới.
      </div>
      <div style={{ fontWeight: "bold" }}>Bảo Mật Thông Tin</div>
      <div>
        Chúng tôi cam kết bảo vệ thông tin cá nhân của bạn và thực hiện các biện
        pháp bảo mật thích hợp để ngăn chặn truy cập trái phép, mất mát hoặc sử
        dụng sai mục đích của thông tin cá nhân. Chúng tôi không chia sẻ thông
        tin cá nhân của bạn với bất kỳ bên thứ ba nào trừ khi cần thiết để cung
        cấp dịch vụ và theo yêu cầu của pháp luật.
      </div>
      <div style={{ fontWeight: "bold" }}>Quyền Kiểm Soát và Truy Cập</div>
      <div>
        Bạn có quyền truy cập, chỉnh sửa hoặc xóa thông tin cá nhân của mình bất
        kỳ lúc nào. Bạn cũng có thể yêu cầu chúng tôi ngừng sử dụng hoặc xóa
        thông tin cá nhân của bạn. Để thực hiện các quyền này hoặc nếu bạn có
        bất kỳ câu hỏi hoặc phản hồi nào về chính sách riêng tư của chúng tôi,
        vui lòng liên hệ với chúng tôi theo thông tin liên hệ dưới đây.
      </div>
      <div style={{ fontWeight: "bold" }}>
        Quyền lựa chọn và cập nhật thông tin:
      </div>
      <div>
        Bạn có quyền lựa chọn không cung cấp thông tin cá nhân và có thể yêu cầu
        cập nhật hoặc xóa thông tin đã được cung cấp trước đó.
      </div>
      <div>Thay đổi Chính sách Quyền Riêng Tư:</div>
      <div>
        Chúng tôi có thể cập nhật Chính sách Quyền Riêng Tư này từ thời gian này
        sang thời gian khác, và bản cập nhật sẽ được đăng tải trên trang web của
        chúng tôi hoặc thông báo qua ứng dụng.
      </div>
      <div style={{ fontWeight: "bold" }}>Liên hệ chúng tôi:</div>
      <div>Email Hỗ Trợ: support@example.com</div>
      <div> Hỗ trợ tại app: Link to screen Support.</div>
      <div>Số điện thoại hỗ trợ khách hàng : 222222</div>
      <div>
        Đảm bảo rằng mọi phần của Chính sách Quyền Riêng Tư tuân thủ các luật
        pháp về quyền riêng tư và được hiểu rõ bởi người dùng.
      </div>
    </div>
  );
}
