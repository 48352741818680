export default function HelpCenter() {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div>
        Tài liệu hướng dẫn: Trung tâm Trợ giúp cung cấp các tài liệu hướng dẫn
        và hướng dẫn sử dụng chi tiết về cách sử dụng ứng dụng AZinsu và các
        tính năng liên quan.
      </div>
      <div style={{ paddingLeft: 20 }}>
        1. Đăng ký · Mở ứng dụng và chọn tùy chọn "Đăng ký".
        <ul style={{ listStyleType: "square" }}>
          <li>Nhập thông tin cá nhân cần thiết như tên, số điện thoại. </li>
          <li>Xác nhận thông tin bằng OTP.</li>
          <li>Nhập mã PIN.</li>
          <li>
            Nhập thông tin user bao gồm : họ và tên, Giới tính, ngày sinh, địa
            chỉ, SĐT, CCCD và Số BHXH. Có thể bỏ qua.
          </li>
          <li>Xác nhận thông tin và hoàn tất quy trình đăng ký.</li>
        </ul>
      </div>
      <div style={{ paddingLeft: 20 }}>
        2. Đăng nhập
        <ul>
          <li>Mở ứng dụng và chọn tùy chọn "Đăng nhập".</li>
          <li>
            Nhập SĐT và mã PIN đã đăng ký để truy cập vào tài khoản của bạn.
          </li>
        </ul>
      </div>
      <div style={{ paddingLeft: 20 }}>
        3. Thêm hợp đồng bảo hiểm
        <ul>
          <li>
            Trong giao diện chính của ứng dụng, chọn tùy chọn "hợp đồng bảo
            hiểm".
          </li>
          <li>Chọn dấu “+” để thêm hợp đồng.</li>
          <li>
            Nhập thông tin về hợp đồng bảo hiểm bao gồm thông tin về bảo hiểm,
            số hợp đồng, CCCD.
          </li>
          <li>Chọn Mail hoặc SĐT để verify thông tin hợp đồng bảo hiểm.</li>
          <li>Xác nhận thông tin hợp đồng.</li>
          <li>Lưu lại thông tin hợp đồng.</li>
        </ul>
      </div>
      <div style={{ paddingLeft: 20 }}>
        4. Tạo claim · Trong giao diện chính của ứng dụng, chọn tùy chọn “Yêu
        cầu bồi thường”
        <ul>
          <li>
            Trong giao diện chính của ứng dụng, chọn tùy chọn “Yêu cầu bồi
            thường”.
          </li>
          <li>Chọn tùy chọn "+" để tạo claim.</li>
          <li>
            Nhập thông tin chi tiết về sự cố hoặc vấn đề cần yêu cầu bồi thường.
          </li>
          <li>Gửi claim và chờ xử lý.</li>
        </ul>
      </div>
      <li style={{ paddingLeft: 20 }}>
        Chọn tùy chọn "+" để tạo claim. · Nhập thông tin chi tiết về sự cố hoặc
        vấn đề cần yêu cầu bồi thường. · Gửi claim và chờ xử lý.
      </li>
      <div style={{ paddingLeft: 20 }}>
        5. Theo dõi bảo lãnh viện phí
        <ul>
          <li>
            Trong giao diện chính của ứng dụng, chọn tùy chọn "Theo dõi bảo lãnh
            viện phí".
          </li>
          <li>
            Xem trạng thái hiện tại của các yêu cầu bảo lãnh viện phí bạn đã
            tạo.
          </li>
          <li>Xem thông tin chi tiết cho từng yêu cầu yêu cầu bảo lãnh.</li>
          <li>
            Nhận thông báo về các cập nhật mới về trạng thái của bảo lãnh viện
            phí.
          </li>
        </ul>
      </div>
      <div style={{ paddingLeft: 20 }}>
        6. Theo dõi Yêu cầu bồi thường · Trong giao diện chính của ứng dụng,
        chọn tùy chọn "Yêu cầu bồi thường".
        <ul>
          <li>
            Xem trạng thái hiện tại của các yêu cầu bồi thường bạn đã tạo.
          </li>
          <li>Xem thông tin chi tiết cho từng yêu cầu yêu cầu bồi thường.</li>
          <li>
            Nhận thông báo về các cập nhật mới về trạng thái của yêu cầu bồi
            thường.
          </li>
        </ul>
      </div>
      <div style={{ paddingLeft: 20 }}>
        7. Theo dõi hợp đồng bảo hiểm .
        <ul>
          <li>
            Trong giao diện chính của ứng dụng, chọn tùy chọn "Hợp đồng của
            tôi".
          </li>
          <li>
            Xem thông tin chi tiết về các hợp đồng bảo hiểm đã thêm và trạng
            thái hiện tại của chúng.
          </li>
        </ul>
      </div>
    </div>
  );
}
